.square {
  width: 45%;
  margin: 1%;
}

@media only screen and (max-width: 700px) {
  .square {
    width: 95%;
    margin: 1%;
  }
}

.wrapper {
  display: grid;
  height: 300px;
  width: 100%;
  vertical-align: top;
  align-items: center;
  justify-items: center;
}

.grid {
  border-collapse: collapse;
  width: 98%;
}

.row-container{
  padding-left: 16px;
  padding-right: 16px;
}
.row {
  margin: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 45px;
  border-bottom: 0.25px solid #c0c0c0;
}
.single {
  padding-top: 15px;
  padding-bottom: 15px;
}

.multi{
  padding-bottom: 5px;
}

.row-single {
  display: flex;
  border-bottom: 0.25px solid gainsboro;
  padding: 10px;
  min-height: 60px;
  align-items: center;
}

.notavailable {
  color: lightgray;
}

.title {
  font-weight: bold;
  font-size: large;
}

.subtitle {
  font-size:medium;
  font-style: italic;
}

.path{
  font-size: medium;
}

@media (prefers-color-scheme: dark) {
  .notavailable {
    color: rgb(78, 74, 74);
  }
}

/* Page */

ion-header ion-toolbar .sc-ion-searchbar-ios-h {
  padding: 10px;
}

ion-header ion-toolbar .sc-ion-searchbar-md {
  padding-left: unset;
  padding-right: unset;
  padding-top: 10px;
  padding-bottom: 20px;
  box-shadow: none !important;
}

ion-title {
  padding-bottom: 10px;
}

.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

/* MENU */

ion-menu {
  max-width: 250px!important;
}

ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header, ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;
  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;
  margin-bottom: 18px;
  color: #757575;
  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-item .selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header, ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;
  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

list-item {
  padding-left: 100px;
}

ion-icon {font-size: 1.5rem;}

.singer-form .modal-wrapper {
  height: 230px;
  width: 300px;
  position: absolute; 
  display: block;  
}
